import seoConfig from '@config/seo.json'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

interface seoProps {
  title: string | any
  description: string | any
  image?: any
}
const SeoHead = ({ title, description, image }: seoProps) => {
  const router = useRouter()

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title: title,
        description: description,
        url: `${seoConfig.openGraph.url}${router.asPath}`,
        images: [
          {
            url:
              image?.url! ||
              image?.originalSrc! ||
              seoConfig.openGraph.images[0].url,
            width: 800,
            height: 600,
            alt: title,
          },
        ],
      }}
    />
  )
}

export default SeoHead
