import { ChevronRight, Email as EmailIcon, Phone } from '@components/icons'
import cn from 'classnames'

interface Props {
  items: [] | any
}

interface LinkProps {
  index: number
  title: string
  extraInfo: string
}

const Email = ({ index, title, extraInfo }: LinkProps) => {
  return (
    <a
      href={`mailto:${extraInfo}`}
      className={cn('block border-greyBg py-3 no-underline', {
        'border-t': index === 0,
        'border-b border-t': !(index === 0),
      })}
    >
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          <EmailIcon />
          <span className="heading-7">{title}</span>
        </div>
        <span className="underline">{extraInfo}</span>
      </div>
    </a>
  )
}
const Call = ({ index, title, extraInfo }: LinkProps) => {
  return (
    <a
      href={`tel:${extraInfo}`}
      className={cn('block border-greyBg py-3 no-underline', {
        'border-t': index === 0,
        'border-b border-t': !(index === 0),
      })}
    >
      <div className="flex justify-between">
        <div className="flex gap-3 items-center">
          <Phone fill="#000" />
          <span className="heading-7">{title}</span>
        </div>
        <div className="relative top-1">
          <ChevronRight />
        </div>
      </div>
    </a>
  )
}

const FancyLinks = ({ items }: Props) => {
  return (
    <div className={cn('mb-6')}>
      {items.map((i: any, index: number) => {
        const { link_type, title1, extra_info } = i
        if (link_type === 'Email')
          return (
            <Email
              key={`email-${index}`}
              index={index}
              title={title1}
              extraInfo={extra_info}
            />
          )
        if (link_type === 'Call')
          return (
            <Call
              key={`call-${index}`}
              index={index}
              title={title1}
              extraInfo={extra_info}
            />
          )
      })}
    </div>
  )
}

export default FancyLinks
