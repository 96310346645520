import { ChevronRight } from '@components/icons'
import cn from 'classnames'
import Link from 'next/link'

interface Props {
  items: [] | any
  primary: any
}

const SitemapLinks = ({ primary, items }: Props) => {
  const { title1 } = primary
  const totalLinks = items?.length
  return (
    <div className={cn('mb-6')}>
      {title1 && (
        <div className="heading-mobile-5 md:heading-6 pb-4">{title1}</div>
      )}
      {items.map((i: any, index: number) => {
        const { label, link } = i
        if (!label || !link) return ''
        return (
          <Link href={link || '/'} passHref key={index}>
            <a
              className={cn('block border-greyBg py-3 no-underline', {
                'border-t': index === 0 && totalLinks > 1,
                'border-b border-t': !(index === 0) || totalLinks < 2,
              })}
            >
              <div className="flex justify-between">
                <div className="flex gap-3 items-center">
                  <span className="heading-7">{label}</span>
                </div>
                <div className="relative top-1">
                  <ChevronRight />
                </div>
              </div>
            </a>
          </Link>
        )
      })}
    </div>
  )
}

export default SitemapLinks
