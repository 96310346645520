import { Container } from '@components/ui'
import seoConfig from '@config/seo.json'
import Image from 'next/image'
import Link from 'next/link'
import { FC, Fragment } from 'react'

interface props {
  primary: any
  items: any
}

const CategoryListV2: FC<props> = ({ primary, items }) => {
  const { openGraph } = seoConfig
  const { title } = primary || {}

  return (
    <Container>
      <div className="pb-10 pt-8 md:pb-20 md:pt-20">
        <div className="">
          {title && (
            <div className="flex pb-8">
              <div className="h-6 w-2 bg-royalBlue mr-3 lg:h-8"></div>
              <h5 className="text-mobileh4 lg:text-h5 font-icomoon text-primary ">
                {title}
              </h5>
            </div>
          )}

          <div className=" grid grid-cols-2 lg:grid-cols-4 gap-8">
            {items?.map((item: any, index: number) => {
              const { category_image, title, link, category_color } = item || {}
              return (
                <Fragment key={index}>
                  {link && (
                    <Link href={link || '/'} passHref>
                      {category_image?.url && (
                        <a className="w-full h-full block no-underline">
                          <Image
                            src={category_image?.url}
                            alt={category_image?.alt || openGraph.images[0].alt}
                            width={304}
                            height={304}
                            layout="responsive"
                          />

                          {title && (
                            <div
                              className="h-12 text-lightGrey flex items-center justify-center no-underline"
                              style={{ background: category_color }}
                            >
                              <span className="text-h7 lg:text-h6 font-icomoon">
                                {title}
                              </span>
                            </div>
                          )}
                        </a>
                      )}
                    </Link>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CategoryListV2
