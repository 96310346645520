import { Container } from '@components/ui'
import { FC } from 'react'

interface props {
  primary: any
}

const IntroTexts: FC<props> = ({ primary }) => {
  const { title, description, subtitle } = primary || {}
  return (
    <Container>
      <div className="bg-lightestGrey py-6 mb-10 lg:mb-20">
        {title && (
          <h4 className="text-h4 font-icomoon text-primary  pb-3 lg:pb-4 text-center">
            {title}
          </h4>
        )}
        {subtitle && (
          <h6 className="text-body1 font-roboto text-primary pb-4 lg:pb-6 text-center">
            {subtitle}
          </h6>
        )}
        {description && (
          <div className="text-body2 text-charcoal px-5 font-roboto mx-auto lg:max-w-5xl text-center">
            {description}
          </div>
        )}
      </div>
    </Container>
  )
}

export default IntroTexts
