import { Breadcrumbs } from '@components/common'
import cn from 'classnames'

interface Props {
  primary: any
  items: [] | any
}

const BreadcrumbSlice = ({ primary, items }: Props) => {
  const { spacing_type } = primary

  return (
    <div className={cn('flex')}>
      <Breadcrumbs
        options={items}
        classNames={cn({
          'pt-4 pb-6 lg:pt-8 lg:pb-8': spacing_type !== 'Below Banner',
          'pt-4 pb-6 lg:pt-6 lg:pb-12': spacing_type === 'Below Banner',
        })}
      />
    </div>
  )
}

export default BreadcrumbSlice
