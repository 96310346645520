import usePrice from '@commerce/product/use-price'
import { Product } from '@commerce/types'

interface Props {
  channelPrice: Product.ProductPrice
}

const PriceByChannel = ({ channelPrice }: Props) => {
  const { price } = usePrice({
    amount: channelPrice?.value,
    baseAmount: channelPrice?.retailPrice,
    currencyCode: channelPrice?.currencyCode!,
  })

  return <>{price}</>
}

export default PriceByChannel
