import { ChevronRight } from '@components/icons'
import { Container } from '@components/ui'
import seoConfig from '@config/seo.json'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

interface props {
  items: any
}

const ServiceBlock: FC<props> = ({ items }) => {
  const { openGraph } = seoConfig
  return (
    <Container>
      <div className=" block pb-10 lg:pb-20 lg:grid grid-cols-3 gap-8">
        {items &&
          items.map((item: any, index: number) => {
            const {
              service_title,
              service_description,
              service_image,
              link_title,
              link,
            } = item || {}
            return (
              <div
                className="bg-lightestGrey pt-10 pb-6 mb-3 lg:mb-0"
                key={index}
              >
                {service_image?.url && (
                  <div className="flex justify-center pb-10">
                    <Image
                      src={service_image?.url}
                      alt={service_image?.alt || openGraph.images[0].alt}
                      width={48}
                      height={48}
                    />
                  </div>
                )}

                <div className="px-12">
                  {service_title && (
                    <h5 className="text-mobileh5 text-center pb-2">
                      {service_title}
                    </h5>
                  )}
                  {service_description && (
                    <div className="text-center text-body1 text-secondary font-roboto pb-3">
                      {service_description}
                    </div>
                  )}
                </div>

                {link && (
                  <Link href={link} passHref>
                    <button className=" w-full text-body2 text-secondary font-roboto flex items-center justify-center ">
                      <div className="flex items-center justify-center">
                        <span className="pr-2">{link_title}</span>
                        <ChevronRight />
                      </div>
                    </button>
                  </Link>
                )}
              </div>
            )
          })}
      </div>
    </Container>
  )
}

export default ServiceBlock
