import ChevronRight from '@components/icons/ChevronRight'
import { Container } from '@components/ui'
import cn from 'classnames'
import { breadCrumbItem } from 'lib/types/Common'
import Link from 'next/link'
import { Fragment } from 'react'

interface breadCrumbs {
  options?: breadCrumbItem[]
  classNames?: string
}

const BreadCrumbs = ({ options, classNames }: breadCrumbs) => {
  if (!options?.length) return <></>
  return (
    <Container>
      <ul className={cn('flex', classNames)}>
        {options &&
          options.map((item, index) => {
            if (!item?.link && options.length !== index + 1) return ''
            if (options.length === index + 1) {
              return (
                <li
                  className="body-2 text-disabled bc-label capitalize"
                  key="current"
                >
                  <label>{item.label}</label>
                </li>
              )
            }
            return (
              <Fragment key={`options-${index}`}>
                <li className="body-2 text-charcoal bc-label capitalize">
                  {item.link && <Link href={item.link}>{item.label}</Link>}
                  {!item.link && <label>{item.label}</label>}
                </li>
                <li className="ml-2.5 mr-2.5 mt-1.5">
                  <ChevronRight alt="Cleanaway" />
                </li>
              </Fragment>
            )
          })}
      </ul>
      <style jsx global>
        {`
          .bc-label a {
            text-decoration: none !important;
          }
        `}
      </style>
    </Container>
  )
}

export default BreadCrumbs
