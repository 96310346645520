import { WYSIWYG } from '@components/ui'
import cn from 'classnames'
import { FC } from 'react'
interface props {
  primary: any
}

const Content: FC<props> = ({ primary }) => {
  return (
    <section>
      <div className={cn('mb-6')}>
        <WYSIWYG content={primary.content} />
      </div>
    </section>
  )
}

export default Content
