import { Address } from '@commerce/schema'
import { Product } from '@commerce/types'
import commerceConfig from '@config/commerce.json'
const { primaryChannelKeyCT } = commerceConfig
export const slugify = (text: string) =>
  text
    .toLocaleLowerCase()
    .trim()
    .replace(/[^\w\- ]+/g, '')
    .replace(/ +/g, '-')
    .replace(/\-\-+/g, '-')

export const getShippingAddress = (shippingAddress: Address | null) => {
  const streetNumber =
    (shippingAddress?.streetNumber && shippingAddress?.streetNumber) || ''
  const streetName =
    (shippingAddress?.streetName && shippingAddress?.streetName) || ''
  const city = (shippingAddress?.city && shippingAddress?.city) || ''
  const state = (shippingAddress?.state && shippingAddress?.state) || ''
  const country = (shippingAddress?.country && shippingAddress?.country) || ''

  const userShippingAddress = `${streetNumber} ${streetName} ${city}, ${state} ${country}`
  return userShippingAddress
}

export const findPriceByChannel = (
  prices: Product.ProductPrice[],
  postcode: string,
  postcodeMapping: any
): Product.ProductPrice => {
  const channelKey =
    postcodeMapping?.filter((m: any) => m.postcode === Number(postcode))?.[0]
      ?.channel_key || primaryChannelKeyCT

  const price = prices?.filter(
    (p: Product.ProductPrice) => p?.channel?.key === channelKey
  )?.[0] ||
    prices?.filter(
      (p: Product.ProductPrice) => p?.channel?.key === primaryChannelKeyCT
    )?.[0] ||
    prices?.[0] || {
      value: 0,
      currencyCode: 'AUD',
      channel: {
        key: primaryChannelKeyCT,
        id: 'not-found',
      },
    }

  return price
}
