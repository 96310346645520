import { ProductCard } from '@components/product'
import { Button, Container } from '@components/ui'
import 'keen-slider/keen-slider.min.css'
import { useState } from 'react'
import FavoriteProducts from './FavoriteProducts'
import ProductsListWithShowMore from './ProductsListWithShowMore'
interface ProductsListProps {
  primary: any
  categoryProducts: any
}

const ProductsList: React.FC<ProductsListProps> = ({
  primary,
  categoryProducts,
}) => {
  const [showMoreProducts, setShowMoreProducts] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const categoryId = primary?.category_id
  const products = categoryProducts?.find(
    (cp: any) => cp.categoryId === categoryId
  )?.products

  const mobileCarousel = primary?.mobile_carousel

  const withShowMoreButton = primary?.show_more_button

  if (mobileCarousel && !withShowMoreButton) {
    return <FavoriteProducts favoriteProducts={products} primary={primary} />
  }
  if (withShowMoreButton && mobileCarousel) {
    return <ProductsListWithShowMore products={products} primary={primary} />
  }

  const handleShowMore = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setShowMoreProducts(!showMoreProducts)
    setShowButton(false)
  }

  const displayProducts = products?.slice(0, 32)

  const leftProducts = products?.length - displayProducts?.length
  const { title } = primary

  const productQuantity = products?.length

  return (
    <Container>
      <div className="pb-10 md:pb-20">
        {title && (
          <div className="flex pb-3 md:pb-8">
            <div className="h-6 w-2 bg-royalBlue mr-3 lg:h-8"></div>
            <h5 className="text-mobileh4 lg:text-h5 font-icomoon text-primary ">
              {title}
            </h5>
          </div>
        )}

        <div className="body2 text-primary text-left font-roboto md:text-right pb-4">
          {productQuantity} products found
        </div>

        <div className="block md:grid grid-cols-2 lg:grid lg:grid-cols-4 gap-8">
          {displayProducts &&
            displayProducts?.map((product: any, index: number) => {
              return (
                <div key={index} className="pb-4 md:pb-0">
                  <ProductCard product={product} />
                </div>
              )
            })}
          {showMoreProducts &&
            products &&
            products?.slice(32).map((product: any, index: number) => {
              return (
                <div key={index} className="pb-4 md:pb-0">
                  <ProductCard product={product} />
                </div>
              )
            })}
        </div>

        {products?.length > 32 && showButton && (
          <div className=" flex justify-center pt-8">
            <Button variant="blackPrimary" onClick={handleShowMore}>
              SHOW {leftProducts} MORE
            </Button>
          </div>
        )}
      </div>
    </Container>
  )
}

export default ProductsList
