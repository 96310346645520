import cn from 'classnames'
import { FC } from 'react'

interface props {
  title?: string | null | undefined
  variant?: 'pdp' | 'card'
}

const ProductTitle: FC<props> = ({ title, variant = 'pdp' }) => {
  if (!title) return <></>
  return (
    <h5
      className={cn({
        'heading-mobile-4 lg:heading-5': variant === 'pdp',
        'heading-mobile-5 lg:heading-6': variant === 'card',
      })}
    >
      {title}
    </h5>
  )
}

export default ProductTitle
