import cn from 'classnames'
import { FC } from 'react'

interface props {
  subtext?: string
  trim?: boolean
}

const ProductShortDescription: FC<props> = ({ subtext, trim = false }) => {
  if (!subtext) return <></>
  return (
    <div className={cn('body-2 text-charcoal', { 'line-clamp-2': trim })}>
      {subtext}
    </div>
  )
}

export default ProductShortDescription
