import type { Product } from '@commerce/types/product'

export const checkStock = (product: Product) => {
  let inStock = false
  if (product?.masterVariant?.isOnStock === true) {
    inStock = true
  }
  product.variants?.forEach((v) => {
    if (v.isOnStock === true) {
      inStock = true
    }
  })
  return inStock
}