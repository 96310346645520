import { Container } from '@components/ui'
import seoConfig from '@config/seo.json'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import Image from 'next/image'
import Link from 'next/link'
import { FC, Fragment, useState } from 'react'
const placeholderImg = '/product-img-placeholder.svg'

interface props {
  primary: any
  items: any
}

const CategoryList: FC<props> = ({ primary, items }) => {
  const { openGraph } = seoConfig
  const { title, description } = primary || {}

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1.2,
      spacing: 16,
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    loop: true,
    created() {
      setLoaded(true)
    },
  })

  return (
    <Container>
      <div className="pb-10 md:pb-20">
        <div className="">
          {title && (
            <h4 className="text-mobileh4 md:text-h4 font-icomoon text-primary pb-3">
              {title}
            </h4>
          )}
          {description && (
            <div className="pb-6 text-body2 font-roboto text-secondary md:pb-8 max-w-lg">
              {description}
            </div>
          )}

          <div className=" hidden md:grid grid-cols-3 gap-8">
            {items?.map((item: any, index: number) => {
              const { category_image, category_title, category_link } =
                item || {}
              return (
                <Fragment key={index}>
                  {category_link && (
                    <Link href={category_link || '/'} passHref>
                      <a className="w-full h-full block no-underline">
                        <Image
                          src={category_image?.url || placeholderImg}
                          alt={category_image?.alt || openGraph.images[0].alt}
                          width={416}
                          height={304}
                          layout="responsive"
                        />

                        {category_title && (
                          <div className="bg-royalBlue h-12 text-lightGrey flex items-center justify-center no-underline">
                            {category_title}
                          </div>
                        )}
                      </a>
                    </Link>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>

        <div className="block h-auto overflow-y-hidden opacity-100 md:h-0 md:opacity-0 ">
          <div ref={sliderRef} className="keen-slider">
            {items &&
              items?.map((item: any, index: number) => {
                const { category_image, category_title, category_link } =
                  item || {}
                return (
                  <Fragment key={index}>
                    {category_link && (
                      <Link href={category_link || '/'} passHref>
                        <a className="w-full h-full block no-underline keen-slider__slide">
                          <Image
                            src={category_image?.url || placeholderImg}
                            alt={category_image?.alt || openGraph.images[0].alt}
                            width={416}
                            height={304}
                            layout="responsive"
                          />
                          {category_title && (
                            <div className="bg-royalBlue h-12 text-lightGrey flex items-center justify-center no-underline">
                              {category_title}
                            </div>
                          )}
                        </a>
                      </Link>
                    )}
                  </Fragment>
                )
              })}
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className="flex justify-center mt-6 bg-lightGrey md:hidden">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <div
                  key={idx}
                  className={
                    'w-11/12 h-1 rounded-md focus:outline-none' +
                    (currentSlide === idx ? ' bg-black' : '')
                  }
                />
              )
            })}
          </div>
        )}
      </div>
    </Container>
  )
}

export default CategoryList
