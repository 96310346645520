import { WYSIWYG } from '@components/ui'
import { slugify } from '@lib/helpers/general'
import cn from 'classnames'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
interface Props {
  items: [] | any
}

const FAQs = ({ items }: Props) => {
  return (
    <div className={cn('mb-6 faqs')}>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="border-t border-greyBg"
      >
        {items.map((item: any) => {
          if (!item?.question) return <></>
          return (
            <AccordionItem
              uuid={slugify(item?.question)}
              key={slugify(item?.question)}
              className="accordion-item border-b border-greyBg"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="flex items-center accordion__button">
                  <h2 className="heading-7">{item?.question}</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-panel pb-4">
                <WYSIWYG content={item?.answer} />
              </AccordionItemPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <style jsx global>
        {`
          .faqs .accordion__button {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            text-align: left;
            padding: 1rem 0 1rem 0;
            transition: color 0.2s linear;
            cursor: pointer;
            background-color: rgb(255, 255, 255);
            text-transform: capitalize;
            color: rgb(0, 0, 0);
          }

          .faqs .accordion__button:focus {
            outline: none;
          }

          .faqs .accordion__button:after,
          .faqs .accordion__button:before {
            content: '';
            position: absolute;
            right: 0px;
            top: 40%;
          }

          .faqs .accordion__button:before {
            color: black;
            width: 0.5rem;
            height: 0.5rem;
            border-top: 1px solid #5a5a5a;
            border-left: 1px solid #5a5a5a;
            border-right: 0;
            border-bottom: 0;
            content: '';
            display: inline-block;
            margin: 0 3px;
            transform: rotate(-135deg);
          }

          .faqs .accordion__button[aria-expanded='true']:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        `}
      </style>
    </div>
  )
}

export default FAQs
