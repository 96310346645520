import { Button } from '@components/ui'
import seoConfig from '@config/seo.json'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

interface props {
  primary: any
}

const SplitBanner: FC<props> = ({ primary }) => {
  const { openGraph } = seoConfig
  const {
    title,
    description,
    banner_image,
    mobile_banner_image,
    button_texts,
    button_link,
  } = primary || {}
  return (
    <div className="mx-auto w-full flex flex-col-reverse lg:flex-row banner bg-royalBlue">
      <div className="w-full pl-5 pr-5 md:pl-16 md:pr-16 py-6 lg:py-0 flex items-center lg:w-2/5 lg:pl-20 lg:pr-20 xl:pl-16 xl:pr-16 splitBannerInfo">
        <div>
          {title && (
            <h4 className="text-mobileh3 xl:text-h4 text-white font-icomoon pb-4">
              {title}
            </h4>
          )}
          {description && (
            <div className="text-body2 text-white font-roboto pb-8">
              {description}
            </div>
          )}

          {button_link && (
            <Link href={button_link} passHref>
              <Button variant="whiteSecondary">{button_texts}</Button>
            </Link>
          )}
        </div>
      </div>
      <div className="w-full lg:w-3/5 relative h-60 md:h-96 lg:h-110 xl:w-2/3">
        <div className="hidden lg:block">
          {banner_image?.url && (
            <Image
              src={banner_image.url}
              alt={banner_image.alt || openGraph.images[0].alt}
              layout="fill"
              objectFit="cover"
            ></Image>
          )}
        </div>
        <div className="lg:hidden h-full w-full ">
          {mobile_banner_image?.url && (
            <Image
              src={mobile_banner_image.url}
              alt={mobile_banner_image.alt || openGraph.images[0].alt}
              layout="fill"
              objectFit="cover"
            ></Image>
          )}
        </div>
      </div>

      <style jsx>
        {`
          @media (min-width: 1700px) {
            .splitBannerInfo {
              padding-left: 13rem !important;
            }
          }
          @media (min-width: 1520px) {
            .splitBannerInfo {
              padding-left: 9rem !important;
            }
          }
          @media (min-width: 1620px) {
            .splitBannerInfo {
              padding-left: 10rem !important;
            }
          }
          @media (min-width: 1700px) {
            .splitBannerInfo {
              padding-left: 13rem !important;
            }
          }
          @media (min-width: 1750px) {
            .splitBannerInfo {
              padding-left: 15rem !important;
            }
          }
          @media (min-width: 1850px) {
            .splitBannerInfo {
              padding-left: 18rem !important;
            }
          }
          @media (min-width: 1920px) {
            .splitBannerInfo {
              padding-left: 19rem !important;
            }
          }
          @media (min-width: 1950px) {
            .splitBannerInfo {
              padding-left: 21rem !important;
            }
          }
          @media (min-width: 2050px) {
            .splitBannerInfo {
              padding-left: 24rem !important;
            }
          }
          @media (min-width: 2460px) {
            .splitBannerInfo {
              padding-left: 36rem !important;
            }
          }
        `}
      </style>
    </div>
  )
}

export default SplitBanner
