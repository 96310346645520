import { Product } from '@commerce/types'
import { Button, useUI } from '@components/ui'
import PriceByChannel from '@components/ui/PriceByChannel'
import seoConfig from '@config/seo.json'
import { checkStock } from '@lib/helpers/check-stock'
import { findPriceByChannel } from '@lib/helpers/general'
import { client } from '@lib/prismic'
import Image, { ImageProps } from 'next/image'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import ProductShortDescription from '../ProductShortDescription'
import ProductTitle from '../ProductTitle'

interface Props {
  className?: string
  product: Product.Product | any
  noNameTag?: boolean
  imgProps?: Omit<ImageProps, 'src' | 'layout' | 'placeholder' | 'blurDataURL'>
  variant?: 'default' | 'slim' | 'simple'
}

const ProductCard: FC<Props> = ({ product }) => {
  const { openGraph } = seoConfig
  const {
    postcode: code,
    setModalView,
    openModal,
    setSidebarView,
    toggleSidebar,
  } = useUI()
  const [priceByChannel, setPriceByChannel] =
    useState<Product.ProductPrice | null>(null)
  const { data: postcodeMappingData } = useSWRImmutable(
    `/postcode-mapping`,
    () => client().getSingle('region_postcode_mapping')
  )

  useEffect(() => {
    if (code && postcodeMappingData) {
      const postcodeMapping: any = postcodeMappingData?.data?.mapping
      setPriceByChannel(
        findPriceByChannel(product?.prices, code, postcodeMapping)
      )
    }
  }, [code, postcodeMappingData, product])

  const categoryProductDescription =
    product &&
    product.masterVariant &&
    product.masterVariant?.attributesRaw?.find(
      (i: any) => i.name === 'product-attribute-short-description'
    )?.value

  const handleLocationSet = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    const width = window.innerWidth
    if (width && width < 1024) {
      setSidebarView('LOCATION_VIEW')
      toggleSidebar()
    } else {
      setModalView('LOCATION_VIEW')
      openModal()
    }
  }

  const inStock =
    checkStock(product) && priceByChannel && priceByChannel?.value > 0

  return (
    <>
      <Link href={`/product/${product.slug}`}>
        <a
          className="no-underline"
          aria-label={product?.name || 'TODO-missing-name'}
        >
          <div className="product-card relative px-4 pb-4 xl:px-6 text-black">
            <div className="product-image bg-transparent">
              {/* removed condition and display fallback image */}
              <>
                <div className="hidden xl:block">
                  <Image
                    src={
                      product?.images[0]?.url || '/product-img-placeholder.svg'
                    }
                    alt={product?.images[0]?.alt || openGraph.images[0].alt}
                    width={304}
                    height={304}
                    layout="responsive"
                  />
                </div>
                <div className="xl:hidden">
                  <Image
                    src={
                      product?.images[0]?.url || '/product-img-placeholder.svg'
                    }
                    alt={product?.images[0]?.alt || openGraph.images[0].alt}
                    width={256}
                    height={256}
                    layout="responsive"
                  />
                </div>
              </>
            </div>
            <div className="productInfo">
              <div className="mt-4">
                <ProductTitle title={product.name} variant="card" />
              </div>
              <div className="mt-2 text-charcoal">
                <ProductShortDescription
                  subtext={
                    product['product-attribute-short-description'] ||
                    categoryProductDescription
                  }
                  trim
                />
              </div>
            </div>

            <div className="w-full">
              {priceByChannel && inStock && (
                <div className="price-3 text-black lg:price-2">
                  <PriceByChannel channelPrice={priceByChannel} />
                </div>
              )}
              {priceByChannel && !inStock && (
                <div>
                  <Button
                    aria-label="Out of stock"
                    type="button"
                    width="100%"
                    disabled
                  >
                    'Out of stock'
                  </Button>
                </div>
              )}
              {!priceByChannel && (
                <>
                  <div className="block">
                    <Button
                      aria-label="Set location to view price"
                      type="button"
                      variant="tertiary"
                      onClick={handleLocationSet}
                      style={{
                        height: '40px',
                        width: '100%',
                        paddingLeft: '0.1rem',
                        paddingRight: '0.1rem',
                      }}
                      className="setLocationPrice"
                    >
                      <span className=" text-2 lg:text-4 leading-6 text-secondary">
                        Set location to view price
                      </span>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </a>
      </Link>
      <style jsx>
        {`
          .product-card {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 2px;
          }
          .productInfo {
            height: 4rem;
            margin-bottom: 4.5rem;
          }

          @media (max-width: 325px) {
            .setLocation {
            }
          }
        `}
      </style>

      <style jsx global>{`
        .setLocationPrice {
          font-size: 0.9rem;
        }
      `}</style>
    </>
  )
}

export default ProductCard
