import { ChevronRight } from '@components/icons'
import { Button } from '@components/ui'
import cn from 'classnames'
import Link from 'next/link'
import { FC } from 'react'
interface props {
  primary: any
}

const DidYouFind: FC<props> = ({ primary }) => {
  const { title1, button_title, button_link } = primary
  return (
    <section>
      <div className={cn('mb-6')}>
        {title1 && <div className="heading-7 text-black">{primary.title1}</div>}
        {button_title && button_link && (
          <Link href={button_link || '/'} passHref>
            <Button variant="link">
              <span className="pr-2.25">{button_title}</span>
              <ChevronRight width="20" height="20" />
            </Button>
          </Link>
        )}
      </div>
    </section>
  )
}

export default DidYouFind
