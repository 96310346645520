import { ProductsList } from '@components/product'
import {
  BreadcrumbSlice,
  CategoryList,
  CategoryListV2,
  Content,
  DidYouFind,
  FancyLinks,
  FAQs,
  IntroTexts,
  ServiceBlock,
  SitemapLinks,
  SplitBanner,
  SplitPanel,
} from '@components/slices'
import { Slice } from '@prismicio/types'

type SliceZoneProps = {
  slices: Slice[] | any
  categoryProducts?: any
}

const SliceZone = ({ slices, categoryProducts }: SliceZoneProps) => {
  return (
    <>
      {slices.map((slice: Slice, index: number) => {
        switch (slice.slice_type) {
          case 'faq':
            return (
              <FAQs key={`${slice.slice_type}-${index}`} items={slice.items} />
            )
          case 'content':
            return (
              <Content
                key={`${slice.slice_type}-${index}`}
                primary={slice.primary}
              />
            )
          case 'did_you_find':
            return (
              <DidYouFind
                key={`${slice.slice_type}-${index}`}
                primary={slice.primary}
              />
            )
          case 'fancy_links':
            return (
              <FancyLinks
                key={`${slice.slice_type}-${index}`}
                items={slice.items}
              />
            )
          case 'category_list':
            return (
              <CategoryList
                primary={slice.primary}
                items={slice.items}
                key={`${slice.slice_type}-${index}`}
              />
            )
          case 'split_panel':
            return (
              <SplitPanel
                primary={slice.primary}
                key={`${slice.slice_type}-${index}`}
              />
            )
          case 'service_block':
            return (
              <ServiceBlock
                items={slice.items}
                key={`${slice.slice_type}-${index}`}
              />
            )
          case 'split_banner':
            return (
              <SplitBanner
                primary={slice.primary}
                key={`${slice.slice_type}-${index}`}
              />
            )
          case 'introtexts':
            return (
              <IntroTexts
                primary={slice.primary}
                key={`${slice.slice_type}-${index}`}
              />
            )

          case 'breadcrumbs':
            return (
              <BreadcrumbSlice
                primary={slice.primary}
                items={slice.items}
                key={`${slice.slice_type}-${index}`}
              />
            )

          case 'sitemap_links':
            return (
              <SitemapLinks
                key={`${slice.slice_type}-${index}`}
                primary={slice.primary}
                items={slice.items}
              />
            )
          case 'category_list_v2':
            return (
              <CategoryListV2
                key={`${slice.slice_type}-${index}`}
                primary={slice.primary}
                items={slice.items}
              />
            )

          case 'product_list':
            return (
              <ProductsList
                primary={slice.primary}
                key={`${slice.slice_type}-${index}`}
                categoryProducts={categoryProducts}
              />
            )
          default:
            return ''
        }
      })}
    </>
  )
}

export default SliceZone
