import { ChevronRight } from '@components/icons'
import { Button, Container } from '@components/ui'
import seoConfig from '@config/seo.json'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

interface props {
  primary: any
}

const SplitPanel: FC<props> = ({ primary }) => {
  const { openGraph } = seoConfig
  const {
    title,
    panel_image_position,
    panel_image,
    panel_title,
    panel_description,
    button_title,
    button_link,
  } = primary || {}

  return (
    <Container>
      <div className="pb-10 lg:pb-20">
        {title && (
          <div className="flex pb-8">
            <div className="h-6 w-2 bg-royalBlue mr-3 lg:h-8"></div>
            <h5 className="text-mobileh4 lg:text-h5 font-icomoon text-primary ">
              {title}
            </h5>
          </div>
        )}
        <div
          className={cn('block lg:flex w-full', {
            'flex-row': panel_image_position === 'left',
            'flex-row-reverse': panel_image_position === 'right',
          })}
        >
          {panel_image?.url && (
            <div className="w-full lg:w-1/2 panelImage ">
              <Image
                src={panel_image?.url}
                alt={panel_image?.alt || openGraph.images[0].alt}
                width={672}
                height={480}
                layout="responsive"
              />
            </div>
          )}

          <div className=" px-4 lg:px-12 pt-4 pb-6 lg:pt-10 xl:pt-28 lg:pb-0 bg-royalBlue text-white w-full lg:w-1/2">
            {panel_title && (
              <h4 className="text-mobileh5 xl:text-h4 md:text-h4 font-icomoon pb-3  lg:pb-4">
                {panel_title}
              </h4>
            )}
            {panel_description && (
              <div className="text-body xl:text-body1 font-roboto pb-8">
                {panel_description}
              </div>
            )}
            <div className="hidden md:block">
              {button_link && (
                <Link href={button_link} passHref>
                  <Button variant="whiteSecondary">{button_title}</Button>
                </Link>
              )}
            </div>
            <div className="block  md:hidden">
              {button_link && (
                <Link href={button_link} passHref>
                  <Button variant="link">
                    <span className="text-body1 font-roboto text-lightGrey pr-2">
                      {button_title}
                    </span>
                    <ChevronRight width="20" height="20" stroke="#F5F5F5" />
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx global>
        {`
          @media (max-width: 425px) {
            .panelImage {
              box-shadow: 3px 1px 8px 1px rgb(0 0 0 / 8%);
              border-radius: 2px 2px 0px 2px;
            }
          }
        `}
      </style>
    </Container>
  )
}

export default SplitPanel
