import { ProductCard } from '@components/product'
import { Container } from '@components/ui'
import cn from 'classnames'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { useState } from 'react'

interface FavoriteProductsProps {
  primary: any
  favoriteProducts: any
}

const FavoriteProducts: React.FC<FavoriteProductsProps> = ({
  primary,
  favoriteProducts,
}) => {
  const { title, columns_option } = primary

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1.5,
      spacing: 16,
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    loop: true,
    created() {
      setLoaded(true)
    },
  })

  return (
    <Container>
      <div className="pb-10 md:pb-20">
        {title && (
          <div className="flex pb-8">
            <div className="h-6 w-2 bg-royalBlue mr-3 lg:h-8"></div>
            <h5 className="text-mobileh4 lg:text-h5 font-icomoon text-primary ">
              {title}
            </h5>
          </div>
        )}

        <div
          className={cn(' hidden  gap-8', {
            'md:grid grid-cols-4 ': columns_option === '4',
            'md:grid grid-cols-3 ': columns_option === '3',
          })}
        >
          {favoriteProducts &&
            favoriteProducts?.map((product: any, index: number) => {
              return (
                <div key={index} className="pb-4 md:pb-0">
                  <ProductCard product={product} />
                </div>
              )
            })}
        </div>

        <div className="block h-auto overflow-y-hidden opacity-100 md:h-0 md:opacity-0 ">
          <div ref={sliderRef} className="keen-slider">
            {favoriteProducts &&
              favoriteProducts.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="pb-10 pr-2 md:pb-0 keen-slider__slide"
                  >
                    <ProductCard product={item} />
                  </div>
                )
              })}
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className="flex justify-center mt-3 bg-lightGrey md:hidden">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <div
                  key={idx}
                  className={
                    'w-11/12 h-1 rounded-md focus:outline-none' +
                    (currentSlide === idx ? ' bg-black' : '')
                  }
                />
              )
            })}
          </div>
        )}
      </div>
    </Container>
  )
}

export default FavoriteProducts
